import React from 'react'

export default function Contact() {

  return (
    <div className="mb-5">
      <h2 className="content-title">Contact</h2>
      <h3>Call or text us at <a href="tel:240-560-8733">240-560-TREE (8733)</a></h3>
      <h3>Text us your address and we will schedule a time to do a bid.</h3>
    </div>
  )
}
